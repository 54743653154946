<template>
	<v-layout v-if="!loading" row wrap justify-center>
		<v-col lg="9" xl="6">
			<r-card>
				<v-card-title>{{ paymentMethodId ? 'Edit' : 'Add' }} Payment Method</v-card-title>
				<AddPaymentMethodForm ref="addPaymentMethodForm" :managing-payment-method="true" class="pa-4" :submit-method="savePaymentMethod" :editing-payment-method="editingPaymentMethod" />
				<div class="pa-4 pt-0 d-flex justify-space-between">
					<r-btn :to="{ name: 'payment-methods', query: $route.query }" color="default">Cancel</r-btn>
					<r-btn @click="savePaymentMethod">Save</r-btn>
				</div>
			</r-card>
		</v-col>
	</v-layout>
</template>

<script>
import AddPaymentMethodForm from '@/components/giving/donorForm/AddPaymentMethodForm.vue';
import { redirectToErrorPage } from '@/helpers/errorHandler';
export default {
	components: {
		AddPaymentMethodForm
	},
	data() {
		return {
			paymentMethodId: this.$route.params.paymentMethodId || null,
			editingPaymentMethod: null,
			loading: true
		};
	},
	async mounted() {
		_REALM.LOADER.show();

		const self = this;
		const individualId = self.$route.query.individualId || _REALM.STORE.accountStore.state.authAccount.individualId;
		const promises = [_REALM.STORE.givingFormStore.setTransactionAccount(individualId), _REALM.STORE.givingStore.settings()];

		if (self.paymentMethodId) {
			promises.push(
				_REALM.STORE.paymentMethodStore.getPaymentMethod(self.paymentMethodId).then(paymentMethod => {
					self.editingPaymentMethod = paymentMethod;
				})
			);
		}

		await Promise.all(promises)
			.then(() => {
				_REALM.LOADER.hide();
				self.loading = false;
			})
			.catch(e => {
				_REALM.ERRORHANDLER.redirectToErrorPage(e);
			});
	},
	methods: {
		savePaymentMethod() {
			const self = this;
			if (!self.$refs.addPaymentMethodForm.validate()) {
				return;
			}

			_REALM.LOADER.show();
			self.$refs.addPaymentMethodForm
				.addPaymentMethod()
				.then(() => {
					_REALM.TOAST.success('Saved payment method.');
					_REALM.VM.$router.push({ name: 'payment-methods', query: self.$route.query });
				})
				.catch(e => {
					_REALM.TOAST.error(e);
				})
				.finally(() => {
					_REALM.LOADER.hide();
				});
		}
	}
};
</script>
